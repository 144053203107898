<template>
    <div id="common">
        common
        <router-link to="/self/settings/common/language">language设置</router-link>
    </div>
</template>
<script>
    export default {}
</script>
<style>
</style>